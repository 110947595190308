<template>
  <el-dialog
    custom-class="commitDialog"
    v-model="isShow"
    width="439px"
    @close="close"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="title">
      <img src="@/assets/images/common/userInfo_head.png" alt="" />
    </div>
    <el-form ref="formVn" class="form" :model="form" :rules="rules">
      <el-form-item label="公司名称" prop="companyName">
        <el-input
          class="companyInput"
          placeholder="请输入您的公司名称"
          v-model="form.companyName"
          @input="companyInputFn"
        ></el-input>
        <ul class="searchBox" v-if="entNameList.length > 0">
          <li
            v-for="(item, index) in entNameList"
            :key="index"
            @click="searchEntFn(item)"
          >
            {{ item.entName }}
          </li>
        </ul>
      </el-form-item>
      <el-form-item label="您的称呼" prop="contactPerson">
        <el-input
          placeholder="请输入您的称呼"
          v-model="form.contactPerson"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="contactPhone">
        <el-input
          placeholder="请输入您的联系电话"
          v-model="form.contactPhone"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="btn" @click="submitForm">提交咨询</div>
  </el-dialog>
</template>

<script lang="ts">
import { ElMessage } from 'element-plus'
import { defineComponent, ref, computed } from 'vue'
import { entInfo } from '@/api/userInfo'
import { findEntInfo, serOrderAdd } from '@/api/entService'
import { useStore } from 'vuex'

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    specsId: {
      default: ''
    },
    entId: {
      default: ''
    }
  },
  setup(props, ct) {
    const isShow = computed({
      get() {
        return props.visible
      },
      set(v) {
        ct.emit('update:visible', v)
      }
    })
    const entNameList = ref<Array<object>>([])
    const sc = ref('')
    const formVn: any = ref('')
    const form = ref({
      companyName: '',
      contactPerson: '',
      contactPhone: ''
    })
    const rules = ref({
      companyName: [
        {
          required: true,
          message: '请输入公司名称',
          trigger: 'blur'
        }
      ],
      contactPerson: [
        {
          required: true,
          message: '请输入您的称呼',
          trigger: 'blur'
        }
      ],
      contactPhone: [
        {
          required: true,
          message: '请输入联系电话',
          trigger: ['blur', 'change']
        },
        {
          validator: (rule: any, value: any, callback: any) => {
            const reg = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/
            if (reg.test(value)) {
              callback()
            } else {
              callback(new Error('请正确输入手机号'))
            }
          },
          trigger: ['blur', 'change']
        }
      ]
    })
    // 节流
    const stores = useStore()
    const throttle = (time: any, callback: any) => {
      let beiginTime = Date.now()
      return function () {
        if (Date.now() - beiginTime > time) {
          callback()
          beiginTime = Date.now()
        }
      }
    }
    // 提交接口
    const orderAddFn = () => {
      formVn.value.validate((result: any) => {
        if (result) {
          const params = {
            entName: form.value.companyName,
            mobile: form.value.contactPhone,
            name: form.value.contactPerson,
            productId: props.entId,
            sc: sc.value,
            specsId: props.specsId
          }
          serOrderAdd(params).then((res: any) => {
            if (res.status === '1') {
              ElMessage.success({
                message: '申请提交成功',
                type: 'success'
              })
              ct.emit('update:visible', false)
            } else {
              ElMessage.error({
                message: '申请提交失败',
                type: 'error'
              })
            }
          })
        }
      })
    }
    // 提交申请按钮
    const submitForm = throttle(1000, orderAddFn)
    const companyInputFn = () => {
      const entName = form.value.companyName
      findEntInfo({ entName }).then((res: any) => {
        entNameList.value = res.data
      })
    }
    const searchEntFn = (item: any) => {
      sc.value = item.socialCreditCode
      form.value.companyName = item.entName
      entNameList.value = []
    }
    const close = () => {
      isShow.value = false
    }
    const getUserInfos = () => {
      if (stores.getters.loginStatus) {
        entInfo().then(res => {
          form.value.companyName = res.data.entName
          form.value.contactPerson = res.data.contact
          form.value.contactPhone = res.data.phone
        })
      }
    }
    getUserInfos()
    return {
      isShow,
      form,
      rules,
      entNameList,
      formVn,
      close,
      companyInputFn,
      searchEntFn,
      submitForm
    }
  }
})
</script>

<style lang="scss" scoped>
.subInfo {
  text-align: center;
  padding: 0 10px;
}
.title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  img {
    margin: 0 auto;
  }
}
.btn {
  width: 292px;
  height: 50px;
  background: linear-gradient(90deg, #f5dba8 0%, #c49d5a 100%);
  box-shadow: 0px 6px 20px rgba(210, 175, 113, 0.3);
  border-radius: 100px;
  font-size: 18px;
  color: #ffffff;
  margin: 40px auto 67px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.form {
  margin-top: 180px;
}
.companyInput {
  position: relative;
}
.searchBox {
  position: absolute;
  top: 65px;
  left: 0;
  width: 100%;
  height: 300px;
  overflow-y: auto;
  background-color: #fff;
  z-index: 100;
  text-align: left;
  padding: 10px;
  box-shadow: 0px 6px 20px rgba(210, 175, 113, 0.3);
  li {
    padding: 5px;
    cursor: pointer;
    &:hover {
      background-color: #f0f0f0;
    }
  }
}
.active {
  display: block !important;
}
:deep(.el-dialog__header) {
  padding: 0;
}
:deep(.el-dialog__body) {
  padding: 10px 50px 30px;
  margin: 0 auto;
  text-align: center;
}
:deep(.el-dialog) {
  border-radius: 8px;
}
</style>
