
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { serviceDetail, newQRCode } from '@/api/entService'
import applyDialog from './components/applyDialog.vue'
import aliSerDialog from './components/aliSerDialog.vue'
import waist from '../components/waist.vue'
import {
  defineComponent,
  ref,
  onBeforeMount
} from 'vue'

const guaList = [
  {
    guaPic: require('@/assets/images/entService/guarantee01.png'),
    guaContent: '1对1企业管家服务'
  },
  {
    guaPic: require('@/assets/images/entService/guarantee02.png'),
    guaContent: '标准服务流程'
  },
  {
    guaPic: require('@/assets/images/entService/guarantee03.png'),
    guaContent: '严格甄选优质企业服务商'
  }
]
export default defineComponent({
  components: {
    applyDialog,
    aliSerDialog,
    waist
  },
  setup() {
    const entId: any = ref('')
    const currentIndex = ref('')
    const specMoney = ref('')
    const consultInfo = ref({})
    const guaranteeList = guaList
    const stores = useStore()
    const route = useRoute()
    const router = useRouter()
    const applyShow = ref<boolean>(false)
    const aliShow = ref<boolean>(false)
    const baseImg = ref(require('@/assets/images/entService/code.png'))
    const specFn = (item: any) => {
      currentIndex.value = item.specsId
      if (item.money === '面议') {
        specMoney.value = item.money
      } else {
        specMoney.value = item.money + '起'
      }
    }
    const getId = () => {
      entId.value = route.query.id
    }
    const getDetail = () => {
      serviceDetail({ id: entId.value }).then(res => {
        consultInfo.value = res.data
        if (res.data.specsList.length > 0) {
          specFn(res.data.specsList[0])
        }
      })
    }
    // 申请服务按钮
    const applyFn = () => {
      if (stores.getters.loginStatus) {
        applyShow.value = true
      } else {
        router.push('/login?redirect=' + route.fullPath + '&showTips=1')
      }
    }
    // 在线咨询按钮
    const aliFn = () => {
      aliShow.value = true
    }
    const getCode = () => {
      const params = {
        url: 'pages/policyServerDetail/policyServerDetail',
        scene: `pro_8_${entId.value}_123456`
        // scene: 'pro_8_129_123456'
      }
      newQRCode(params).then((res: any) => {
        if (res.status === '1') {
          baseImg.value = `data:image/jpeg;base64,${res.data}`
        }
      })
    }
    onBeforeMount(() => {
      getId()
      getDetail()
      getCode()
    })
    return {
      entId,
      currentIndex,
      consultInfo,
      guaranteeList,
      applyShow,
      baseImg,
      aliShow,
      specMoney,
      specFn,
      applyFn,
      aliFn
    }
  }
})
