<template>
  <div class="serviceConsult">
    <div class="entDetail_con main_width">
      <div class="pic">
        <img :src="consultInfo.itemUrl" alt="" />
      </div>
      <div class="ent_right">
        <div class="title">{{ consultInfo.typeName }}</div>
        <div class="lightspot">
          <span v-if="consultInfo.title">{{ consultInfo.title }}</span>
        </div>
        <div class="money">
          <span class="com_style">服务费用：</span
          ><span class="num">{{ specMoney }}</span>
        </div>
        <div class="specification">
          <span class="com_style">服务规格：</span>
          <ul class="spec_list">
            <li
              v-for="item in consultInfo.specsList"
              :key="item.specsId"
              @click="specFn(item)"
              :class="{ active: currentIndex === item.specsId }"
            >
              {{ item.name
              }}<img src="@/assets/images/entService/tick.png" alt="" />
            </li>
          </ul>
        </div>
        <div class="guarantee">
          <span class="com_style">服务保障：</span>
          <ul class="gua_list">
            <li v-for="(item, index) in guaranteeList" :key="index">
              <img :src="item.guaPic" alt="" />
              <span>{{ item.guaContent }}</span>
            </li>
          </ul>
        </div>
        <div class="applyBtn" @click="applyFn">申请该服务</div>
        <div class="consultBtn" @click="aliFn">在线咨询</div>
      </div>
    </div>
    <div class="serDetail_con main_width">
      <div class="ser_detail">
        <h3>服务详情</h3>
        <div class="detail_pic" v-html="consultInfo.contentDetail"></div>
      </div>
      <div class="detail_code">
        <img :src="baseImg" alt="" />
        <p>打开微信扫码分享给朋友</p>
      </div>
    </div>
    <waist />
    <applyDialog
      v-model:visible="applyShow"
      :specsId="currentIndex"
      :entId="entId"
    />
    <aliSerDialog v-model:visible="aliShow" />
  </div>
</template>

<script lang="ts">
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { serviceDetail, newQRCode } from '@/api/entService'
import applyDialog from './components/applyDialog.vue'
import aliSerDialog from './components/aliSerDialog.vue'
import waist from '../components/waist.vue'
import {
  defineComponent,
  ref,
  onBeforeMount
} from 'vue'

const guaList = [
  {
    guaPic: require('@/assets/images/entService/guarantee01.png'),
    guaContent: '1对1企业管家服务'
  },
  {
    guaPic: require('@/assets/images/entService/guarantee02.png'),
    guaContent: '标准服务流程'
  },
  {
    guaPic: require('@/assets/images/entService/guarantee03.png'),
    guaContent: '严格甄选优质企业服务商'
  }
]
export default defineComponent({
  components: {
    applyDialog,
    aliSerDialog,
    waist
  },
  setup() {
    const entId: any = ref('')
    const currentIndex = ref('')
    const specMoney = ref('')
    const consultInfo = ref({})
    const guaranteeList = guaList
    const stores = useStore()
    const route = useRoute()
    const router = useRouter()
    const applyShow = ref<boolean>(false)
    const aliShow = ref<boolean>(false)
    const baseImg = ref(require('@/assets/images/entService/code.png'))
    const specFn = (item: any) => {
      currentIndex.value = item.specsId
      if (item.money === '面议') {
        specMoney.value = item.money
      } else {
        specMoney.value = item.money + '起'
      }
    }
    const getId = () => {
      entId.value = route.query.id
    }
    const getDetail = () => {
      serviceDetail({ id: entId.value }).then(res => {
        consultInfo.value = res.data
        if (res.data.specsList.length > 0) {
          specFn(res.data.specsList[0])
        }
      })
    }
    // 申请服务按钮
    const applyFn = () => {
      if (stores.getters.loginStatus) {
        applyShow.value = true
      } else {
        router.push('/login?redirect=' + route.fullPath + '&showTips=1')
      }
    }
    // 在线咨询按钮
    const aliFn = () => {
      aliShow.value = true
    }
    const getCode = () => {
      const params = {
        url: 'pages/policyServerDetail/policyServerDetail',
        scene: `pro_8_${entId.value}_123456`
        // scene: 'pro_8_129_123456'
      }
      newQRCode(params).then((res: any) => {
        if (res.status === '1') {
          baseImg.value = `data:image/jpeg;base64,${res.data}`
        }
      })
    }
    onBeforeMount(() => {
      getId()
      getDetail()
      getCode()
    })
    return {
      entId,
      currentIndex,
      consultInfo,
      guaranteeList,
      applyShow,
      baseImg,
      aliShow,
      specMoney,
      specFn,
      applyFn,
      aliFn
    }
  }
})
</script>

<style lang="scss" scoped>
.serviceConsult {
  background: #f9f9f9;
  padding-top: 135px;
  .entDetail_con {
    display: flex;
    // height: 505px;
    padding: 46px 60px 45px 42px;
    background-color: #fff;
    box-shadow: 0px 8px 5px rgba(242, 242, 242, 0.5);
    border-radius: 6px;
    .pic {
      position: relative;
      width: 335px;
      height: 187px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
      p {
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 36px;
        font-weight: 700;
      }
    }
    .ent_right {
      position: relative;
      flex: 1;
      margin-left: 30px;
      height: 100%;
      .title {
        font-size: 26px;
        font-weight: bold;
        color: #333333;
      }
      .lightspot {
        width: 100%;
        height: 27px;
        margin-top: 25px;
        span {
          display: inline-block;
          background: #fce9e9;
          border-radius: 3px;
          font-size: 14px;
          color: #f32b2e;
          padding: 4px 9px;
        }
      }
      .com_style {
        display: inline-block;
        width: 80px;
        font-size: 16px;
        color: #999999;
      }
      .money {
        display: flex;
        align-items: center;
        margin-top: 25px;
        .num {
          font-size: 32px;
          color: #ff5b60;
        }
      }
      .specification {
        display: flex;
        align-items: flex-start;
        margin-top: 35px;
        .spec_list {
          flex: 1;
          display: flex;
          width: 450px;
          flex-wrap: wrap;
          margin-bottom: 120px;
          li {
            position: relative;
            background: #ffffff;
            border: 1px solid #d2d2d2;
            border-radius: 3px;
            font-size: 14px;
            color: #333333;
            padding: 7px 17px;
            margin-right: 9px;
            margin-bottom: 20px;
            cursor: pointer;
            img {
              display: none;
              position: absolute;
              right: 0;
              top: 0;
              width: 17px;
              height: 15px;
            }
          }
          .active {
            border: 1px solid #c8a260;
            color: #c8a260;
            img {
              display: block;
            }
          }
        }
      }
      .guarantee {
        position: absolute;
        bottom: 0;
        display: flex;
        align-items: center;
        padding-top: 45px;
        border-top: 1px solid #f1f1f1;
        margin-top: 22px;
        .gua_list {
          display: flex;
          li {
            display: flex;
            align-items: center;
            margin-right: 65px;
            &:last-child {
              margin: 0;
            }
            img {
              width: 44px;
              height: 44px;
            }
            span {
              margin-left: 10px;
              font-size: 14px;
              color: #555555;
            }
          }
        }
      }
      .applyBtn,
      .consultBtn {
        position: absolute;
        right: 0;
        width: 190px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        font-size: 16px;
        cursor: pointer;
      }
      .applyBtn {
        top: 14px;
        background: linear-gradient(90deg, #f5dba8 0%, #c49d5a 100%);
        box-shadow: 0px 6px 20px rgba(210, 175, 113, 0.3);
        font-weight: bold;
        color: #ffffff;
      }
      .consultBtn {
        top: 102px;
        border: 1px solid #c8a260;
        color: #c8a260;
      }
    }
  }
  .serDetail_con {
    margin-top: 34px;
    padding-bottom: 54px;
    display: flex;
    justify-content: space-between;
    .ser_detail {
      width: 845px;
      background: #ffffff;
      box-shadow: 0px 8px 5px rgba(242, 242, 242, 0.5);
      border-radius: 6px;
      padding: 45px;
      h3 {
        font-size: 26px;
        font-weight: bold;
        color: #555555;
      }
      .detail_pic {
        width: 100%;
        height: 100%;
        margin-top: 22px;
        img {
          width: 100%;
        }
      }
    }
    .detail_code {
      width: 320px;
      height: 260px;
      background: #ffffff;
      box-shadow: 0px 8px 5px rgba(242, 242, 242, 0.5);
      border-radius: 6px;
      text-align: center;
      img {
        display: inline-block;
        width: 130px;
        height: 130px;
        margin-top: 43px;
      }
      p {
        font-size: 14px;
        color: #999999;
        margin-top: 16px;
      }
    }
  }
}
</style>
