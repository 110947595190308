
import { ElMessage } from 'element-plus'
import { defineComponent, ref, computed } from 'vue'
import { entInfo } from '@/api/userInfo'
import { findEntInfo, serOrderAdd } from '@/api/entService'
import { useStore } from 'vuex'

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    specsId: {
      default: ''
    },
    entId: {
      default: ''
    }
  },
  setup(props, ct) {
    const isShow = computed({
      get() {
        return props.visible
      },
      set(v) {
        ct.emit('update:visible', v)
      }
    })
    const entNameList = ref<Array<object>>([])
    const sc = ref('')
    const formVn: any = ref('')
    const form = ref({
      companyName: '',
      contactPerson: '',
      contactPhone: ''
    })
    const rules = ref({
      companyName: [
        {
          required: true,
          message: '请输入公司名称',
          trigger: 'blur'
        }
      ],
      contactPerson: [
        {
          required: true,
          message: '请输入您的称呼',
          trigger: 'blur'
        }
      ],
      contactPhone: [
        {
          required: true,
          message: '请输入联系电话',
          trigger: ['blur', 'change']
        },
        {
          validator: (rule: any, value: any, callback: any) => {
            const reg = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/
            if (reg.test(value)) {
              callback()
            } else {
              callback(new Error('请正确输入手机号'))
            }
          },
          trigger: ['blur', 'change']
        }
      ]
    })
    // 节流
    const stores = useStore()
    const throttle = (time: any, callback: any) => {
      let beiginTime = Date.now()
      return function () {
        if (Date.now() - beiginTime > time) {
          callback()
          beiginTime = Date.now()
        }
      }
    }
    // 提交接口
    const orderAddFn = () => {
      formVn.value.validate((result: any) => {
        if (result) {
          const params = {
            entName: form.value.companyName,
            mobile: form.value.contactPhone,
            name: form.value.contactPerson,
            productId: props.entId,
            sc: sc.value,
            specsId: props.specsId
          }
          serOrderAdd(params).then((res: any) => {
            if (res.status === '1') {
              ElMessage.success({
                message: '申请提交成功',
                type: 'success'
              })
              ct.emit('update:visible', false)
            } else {
              ElMessage.error({
                message: '申请提交失败',
                type: 'error'
              })
            }
          })
        }
      })
    }
    // 提交申请按钮
    const submitForm = throttle(1000, orderAddFn)
    const companyInputFn = () => {
      const entName = form.value.companyName
      findEntInfo({ entName }).then((res: any) => {
        entNameList.value = res.data
      })
    }
    const searchEntFn = (item: any) => {
      sc.value = item.socialCreditCode
      form.value.companyName = item.entName
      entNameList.value = []
    }
    const close = () => {
      isShow.value = false
    }
    const getUserInfos = () => {
      if (stores.getters.loginStatus) {
        entInfo().then(res => {
          form.value.companyName = res.data.entName
          form.value.contactPerson = res.data.contact
          form.value.contactPhone = res.data.phone
        })
      }
    }
    getUserInfos()
    return {
      isShow,
      form,
      rules,
      entNameList,
      formVn,
      close,
      companyInputFn,
      searchEntFn,
      submitForm
    }
  }
})
