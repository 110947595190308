
import { defineComponent, computed } from 'vue'

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ct) {
    const isShow = computed({
      get() {
        return props.visible
      },
      set(v) {
        ct.emit('update:visible', v)
      }
    })
    const close = () => {
      isShow.value = false
    }
    return {
      isShow,
      close
    }
  }
})
