<template>
  <el-dialog
    custom-class="aliDialog"
    v-model="isShow"
    width="1000px"
    @close="close"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <iframe
      src="https://cschat-ccs.aliyun.com/index.htm?tntInstId=_1F1v2Jc&scene=SCE00007430"
      width="1000px"
      height="600px"
      frameborder="0"
    ></iframe>
  </el-dialog>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ct) {
    const isShow = computed({
      get() {
        return props.visible
      },
      set(v) {
        ct.emit('update:visible', v)
      }
    })
    const close = () => {
      isShow.value = false
    }
    return {
      isShow,
      close
    }
  }
})
</script>

<style lang="scss">
.aliDialog .el-dialog__body {
  padding: 40px 0 !important;
}
</style>
